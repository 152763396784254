



























































































































































import Vue, { PropType } from "vue";
import InvoiceOverviewSearchbar from "@/components/invoice-overview-searchbar.vue";
import {required, between} from "vuelidate/lib/validators";
import { ProductData } from "@/types";
import i18n from "@/i18n/i18n";
export default Vue.extend({
  components: {
    InvoiceOverviewSearchbar,
  },

  props: {
    products: Array as PropType<Array<ProductData>>,
    isSubjectToReverseCharge: Boolean as PropType<boolean>,
    selectedInvoiceType: Number as PropType<number>
  },


  data(){
    return {
      invoiceItems:[],
      invoiceItem: {
        venue: {},
        product: {},
        products:[],
      },
      invoiceItemSubmitted: false,
      newInvoiceItemSubmitted: false,
      newInvoiceItemProduct: {
        product: {
          paymentMethod: '',
          price: 0,
        }
      },
      taxPercentage: 20,
      selectedPaymentMethod: '',
      paymentMethodOptions:[
        {
          value: 1,
          text: "Bank transfer"
        },
        {
          value: 2,
          text: "Credit card"
        }
      ],

    }
  },

  //@ts-ignore
  validations: {
    invoiceItem: {
      venue: {required},
      product: {required},
    },
    newInvoiceItemProduct: {
      product:{
        price: {required},
      },
    },
    taxPercentage: {between: between(0,100)}
  },

  computed: {
    locale(){
      return i18n.locale
    }
  },

  methods: {
    setVenue(val){
      this.invoiceItem.venue = val
    },
    addInvoiceItemProduct(index: number){
      this.newInvoiceItemSubmitted = true;
      this.$v.$touch();

      if(this.$v.newInvoiceItemProduct.$invalid) return;
      if(this.$v.taxPercentage.$invalid) return;

      this.invoiceItems[index].products.push(Object.assign({}, this.newInvoiceItemProduct.product))
      this.newInvoiceItemProduct = {
        product: {
          paymentMethod: '',
          price: 0,
        }
      };

      this.newInvoiceItemSubmitted = false;
    },
    createInvoiceItem(){
      this.invoiceItemSubmitted = true;
      this.$v.$touch();

      if (this.$v.invoiceItem.$invalid) return;
      if(this.$v.taxPercentage.$invalid) return;

      this.invoiceItem.products.push(Object.assign({}, this.invoiceItem.product))
      delete this.invoiceItem['product']
      this.invoiceItems.push(Object.assign({}, this.invoiceItem))
      this.invoiceItem ={
        venue: {},
        product: {},
        products:[],
      };
      this.$emit('invoiceItemsUpdated', this.invoiceItems)
      this.invoiceItemSubmitted = false;
    },

    removeInvoiceItem(index: number){
      this.invoiceItems.splice(index,1)
      this.$emit('invoiceItemsUpdated', this.invoiceItems)
    },

    removeInvoiceItemsItem(index: number, productIndex: number){
      this.invoiceItems[index].products.splice(productIndex, 1)
      this.$emit('invoiceItemsUpdated', this.invoiceItems)
    }
  },
  watch: {
    taxPercentage: {
      immediate: true,
      handler(value: string): void{
        if(value){
          this.$emit('taxRateChanged', this.taxPercentage)
        }
      }
    },
    isSubjectToReverseCharge:{
      immediate: true,
      handler(value: boolean): void{
        if(value == true){
          this.taxPercentage = 0;
          this.$emit('taxRateChanged', this.taxPercentage)
      } else {
          this.taxPercentage = 20;
          this.$emit('taxRateChanged', this.taxPercentage)
        }
      },
    },
    selectedPaymentMethod: {
      immediate: true,
      handler(value): void{
        this.$emit('selectedPaymentMethodChanged', this.selectedPaymentMethod)
      }
    }
  }
})

