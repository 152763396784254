

























import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({

  async created(){
    await this.assignSupplierData()
  },

  data(){
    return{
      supplierName: '',
      streetNameAndNumber: '',
      postalCode: '',
      city: '',
      vatNumber: '',
      businessIdentificationNumber: ''
    }
  },

  methods:{
    ...mapActions('azDataFeedModule', {
      setDataFeed: "FETCH_DATAFEED"
    }),
    async assignSupplierData(){
      await this.setDataFeed({page: 1})
      this.supplierName = this.datafeed[0].supplierName
      this.streetNameAndNumber = this.datafeed[0].streetNameAndNumber
      this.postalCode = this.datafeed[0].postalCode
      this.city = this.datafeed[0].city
      this.vatNumber = this.datafeed[0].vatNumber
      this.businessIdentificationNumber = this.datafeed[0].businessIdentificationNumber
    }
  }
})
