









































































import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "@/mixins/DatePicker";
import i18n from "@/i18n/i18n";
export default Vue.extend({
  mixins: [DatePicker],
  data(){
    return{
      i18n: i18n,
      invoiceNumber: "",
      iban: "",
      bankName: "",
      swift: "",
      variableSymbol: '',
      dueDate: '',
    }
  },

  props: {
    finalPrice: Number as PropType<number>,
    taxPercentage: Number as PropType<number>,
    vuelidate: Object as PropType<object>,
    invoiceSubmitted: Boolean as PropType<boolean>,
    selectedInvoiceType: Number as PropType<number>
  },

  async created(){
    await this.assignSelectedPaymentInstrument()
  },

  computed: {
    ...mapGetters('azDataFeedModule', {
      selectedPaymentInstrument: "GET_SELECTED_DATAPAYIN"
    }),

    totalWithTax(){
      if (!this.finalPrice) return 0.00;
      if(!this.taxPercentage) return this.finalPrice.toFixed(2)
      return (this.finalPrice * (100+this.taxPercentage)/100).toFixed(2)
    }
  },

  methods: {
    ...mapActions('azDataFeedModule', {
      setDataFeed: "FETCH_DATAFEED"
    }),

   async assignSelectedPaymentInstrument(){
      await this.setDataFeed({page: 1})
      this.iban = this.selectedPaymentInstrument[0].iban
      this.bankName = this.selectedPaymentInstrument[0].bankName
      this.swift = this.selectedPaymentInstrument[0].swiftCode
    }
  },
  watch:{
    invoiceNumber(newVal){
      this.$emit('invoiceNumberChange', this.invoiceNumber)
    },
    bankName(newVal){
      this.$emit('bankNameChange', this.bankName)
    },
    swift(newVal){
      this.$emit('swiftChange', this.swift)
    },
    iban(newVal){
      this.$emit('ibanChange', this.iban)
    },
    variableSymbol(newVal){
      this.$emit('variableSymbolChange', this.variableSymbol)
    },
    dueDate(newVal){
      this.$emit('dueDateChange', this.dueDate)
    }
  }
})
