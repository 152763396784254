






































import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  props: {
    finalPrice: Number as PropType<number>,
    taxPercentage: Number as PropType<number>,
    selectedInvoiceType: Number as PropType<number>,
  },

  computed: {
    totalWithTax(){
      if (!this.finalPrice) return 0.00;
      if(!this.taxPercentage) return this.finalPrice.toFixed(2)
      return (this.finalPrice * (100+this.taxPercentage)/100).toFixed(2)
    }
  }



})

