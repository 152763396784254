
























import Vue, { PropType } from "vue";
import i18n from "@/i18n/i18n";
import { mapActions, mapGetters } from "vuex";



export default Vue.extend({
  props: {
    selectedCompanyData: Object,
  },

  data() {
    return {
      searchText: "",
      showList: false,
    };
  },

  computed:{
    locale(){
      return i18n.locale
    },
    selectedBillingName(){
      return this.selectedCompanyData.billing_name == undefined ? i18n.t('Company name') : this.selectedCompanyData.billing_name
    },
    selectedBillingAddress(){
      return this.selectedCompanyData.billing_street == undefined ? i18n.t('Company address') : `${this.selectedCompanyData.billing_street}, ${this.selectedCompanyData.billing_zip}`
    },
    selectedBillingCity(){
      return this.selectedCompanyData.billing_city == undefined ? i18n.t('Billing city') : `${this.selectedCompanyData.billing_city}, ${this.selectedCompanyData.billing_zip}`
    },
    selectedBillingCountry(){
      return this.selectedCompanyData.billing_country_id == undefined ? i18n.t('Billing country') : ("Slovakia")
    },
    selectedBillingVat(){
      return this.selectedCompanyData.billing_vat == undefined ? "" : this.selectedCompanyData.billing_vat
    },
    selectedBillingIco(){
      return this.selectedCompanyData.billing_ico == undefined ? "": this.selectedCompanyData.billing_ico
    }
  }



});
