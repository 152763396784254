




























































































































































import Layout from "@/router/layouts/main.vue";
import Vue from "vue";
import Supplier from "@/components/invoices/new-invoice/Supplier.vue";
import Purchaser from "@/components/invoices/new-invoice/Purchaser.vue";
import PaymentInstructions from "@/components/invoices/new-invoice/PaymentInstructions.vue";
import ServiceTable from "@/components/invoices/new-invoice/ServiceTable.vue";
import Total from "@/components/invoices/new-invoice/Total.vue";
import InvoiceOverviewSearchbar from "@/components/invoice-overview-searchbar.vue";
import {required} from "vuelidate/lib/validators";
import DatePicker from "@/mixins/DatePicker";
import i18n from "@/i18n/i18n";
import { mapActions, mapGetters } from "vuex";
import { finland } from "jsvat";
export default Vue.extend({
  mixins: [DatePicker],
  components: {
    Layout,
    Supplier,
    Purchaser,
    InvoiceOverviewSearchbar,
    PaymentInstructions,
    ServiceTable,
    Total,
  },
  async mounted(){
    await this.setProducts()
  },
  created() {
    if (this.datafeed.length < 1) {
      this.fetchDatafeed({ page: 1 });
    }
  },

  data() {
    return {
      i18n: i18n,
      lang: DatePicker,
      invoiceDuration: [],
      deliveryDate: [],
      invoiceItems: {},
      products:{},
      taxPercentage:'',
      selectedPaymentMethod:'',
      finalPrice: '',
      sizes: ['Small', 'Medium', 'Large', 'Extra Large'],
      selectedInvoiceType:{
        id: 2,
        name: i18n.t('Invoice manual')
      },
      selectedCompanyData:{},
      title: "New invoice/Credit/Debit note",
      // ['Invoice Manual','Debit Note', 'Credit Note']
      invoiceTypeOptions: [
        {
          id: 2, name: i18n.t('Invoice manual')
        },
        {
          id: 3, name:"Debit Note"
        },
        {
          id: 4, name: i18n.t('Credit note')
        }
      ],
      isSubjectToReverseCharge: false,
      invoiceNumber: '',
      bankName: '',
      swift: '',
      iban: '',
      dueDate: '',
      variableSymbol: '',
      invoiceSubmitted: false,
    };
  },

  //@ts-ignore
  validations: {
    selectedCompanyData: {required},
    invoiceDuration: {required},
    deliveryDate: {required},
    invoiceNumber: {required},
    bankName: {required},
    swift: {required},
    iban: {required},
    variableSymbol: {required},
    dueDate: {required}
  },

  computed:{
    ...mapGetters('azProductsModule',{
      allProducts: "GET_ALL_PRODUCTS"
    }),
    subjectToReverseChargeOptions(){
      return[
      {
        value: true, text: i18n.t('Subject to reverse charge')
      },
      {
        value: false, text: i18n.t('Local supply')
      }
    ]},
    computedInvoiceNumber(){
      return this.invoiceNumber
    },
    computedFinalPrice(){
      if(!this.invoiceItems.length) return 0.00;
      let finalPrice = 0;

      for (const invoiceItem of this.invoiceItems){
        for(const invoiceItemProduct of invoiceItem.products){
          finalPrice = finalPrice + Number (invoiceItemProduct.price);
        }
      }
      return finalPrice
    }
  },

  methods:{
    ...mapActions('azProductsModule', {
      fetchProducts: "FETCH_PRODUCTS"
    }),
    ...mapActions('azBillingModule', {
      createNewInvoice: "CREATE_INVOICE"
    }),
    async setProducts(){
      await this.fetchProducts();
      this.products = this.allProducts
    },
    setCompany(company){
      this.selectedCompanyData = company
    },
    async createInvoice(){
      this.invoiceSubmitted = true;
      this.$v.$touch();
      if(this.$v.$invalid) return;

      const invoicePayload = {
        startSubscriptionPeriodDate: String (Math.floor(new Date(this.invoiceDuration[0]).getTime() / 1000)),
        endSubscriptionPeriodDate: String (Math.floor(new Date(this.invoiceDuration[1]).getTime() / 1000)),
        startPeriodInDateTime: this.invoiceDuration[0],
        endPeriodInDateTime: this.invoiceDuration[1],
        dueDate: String(Math.floor(new Date(this.dueDate).getTime() / 1000)),
        status: 'due',
        taxPercentage: this.taxPercentage,
        issueDate: String (Math.floor(Date.now() / 1000)),
        venueOwnerId: this.selectedCompanyData.id,
        venueOwnerName: this.selectedCompanyData.billing_name ? this.selectedCompanyData.billing_name : this.selectedCompanyData.legal_entity_name,
        invoiceNumber: this.invoiceNumber,
        variableSymbol: this.variableSymbol,
        invoiceType: this.selectedInvoiceType.id,
        paymentType: this.selectedPaymentMethod,
        isCorporate: true
      };
      const invoiceItemsPayload = {
        invoiceItems: this.invoiceItems
      };
      await this.createNewInvoice({invoicePayload: invoicePayload, invoiceItemsPayload: invoiceItemsPayload, isSubjectToReverseCharge: this.isSubjectToReverseCharge})
      this.invoiceSubmitted = false;
    }
  },
});
